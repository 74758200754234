import React from 'react';
import { List, Button, Alert } from '../../../shared';
import { BankAccount, ConsumerBank } from '../../../../types/bank-types';
import * as StringHelper from '../../../../helpers/string-helper';
import * as DisplayHelper from '../../../../helpers/display-helper';

interface BankAccountListProps {
  accounts: BankAccount[]
  className?: string
  onDownloadStatements: () => void
  downloadStatementsSuccess?: boolean
  onBack?: () => void
  loading?: boolean
  loadingNMessage?: string
  bank: ConsumerBank
}

const BankAccountList: React.FC<BankAccountListProps> = ({ accounts, className, loading, loadingNMessage, onBack, onDownloadStatements,
  downloadStatementsSuccess }) => {

  const getDisplay = (account: BankAccount) => {
    const parts: string[] = [];
    if (account.accountHolder) parts.push(account.accountHolder);
    if (account.accountType) parts.push(account.accountType);
    if (account.bsb) parts.push(account.bsb.toString());
    if (account.accountNumber) parts.push(account.accountNumber.toString());
    if (!parts.length) parts.push('Unknown');
    return StringHelper.capitaliseWords(parts.join(' - '));
  }

  return (
    <div className={className}>
      <List
        data-test='bank-account-list'
        className='mb-2'
        listItemBorderLeft
        divider
        loadingSkeleton={loading && !loadingNMessage}
        loading={loading && loadingNMessage ? true : false}
        loadingMessage={loadingNMessage}
        items={accounts}
        primaryTextFunc={getDisplay}
        secondaryTextFunc={a => DisplayHelper.money(a.currentBalance)}
      />

      {!downloadStatementsSuccess && (
        <React.Fragment>
          <hr />
          <Button
            disabled={loading}
            onClick={onDownloadStatements}
            bsVariant='primary'
            data-test='bank-account-list-download-button'
            text='Download Statements'
          />
          {onBack && (
            <Button
              disabled={loading}
              className='ml-2'
              onClick={onBack}
              data-test='bank-account-list-back-button'
              text='Back'
            />
          )}
        </React.Fragment>
      )}
      {downloadStatementsSuccess && (
        <React.Fragment>
          <hr />
          <Alert variant='success' center message='Statements Downloaded!' />
        </React.Fragment>
      )}
    </div>
  );
}

export default BankAccountList;
