import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import * as BankApi from '../../../../api/bank';
import DashboardPage from '../../../hoc/dashboard-page';
import { BlockUi, CardSimple } from '../../../shared';
import { BankActivityStatistics, BankStatisticDateGroup } from '../../../../types/bank-types';
import WithApiHelper, { WithApiHelperProps } from '../../../hoc/with-api-helper';
import * as Service from './bank-statistics-service';
import Graph from './bank-activity-date-graph';
import GraphTypeForm from './graph-type-form';
import { addSpacesOnCaps } from '../../../../helpers/string-helper';

interface BankActivityProps extends RouteComponentProps, WithApiHelperProps {
}

interface State {
  loading: boolean
  rawStats: BankActivityStatistics
  graphData: BankStatisticDateGroup[]
  graphType: Service.BankActivityType
  dateType: Service.DateRangeType
}

const BankActivity: React.FC<BankActivityProps> = ({ apiHelper }) => {

  const [state, setState] = useState<State>({
    loading: true,
    rawStats: undefined as any,
    graphData: undefined as any,
    graphType: 'LoggedIn',
    dateType: 'LastWeek'
  });

  apiHelper.setDefaultOnErrorAction(() => setState((ps) => ({ ...ps, loading: false })));

  const reloadStats = () => {
    setState((ps) => ({ ...ps, loading: true }));
    apiHelper
      .makeCall(() => BankApi.getStatistics())
      .then(r => setState((ps) => ({ ...ps, loading: false, rawStats: r.data })))
  }

  const setGraphData = () => {
    if (state.rawStats) {
      const group = Service.getStatisticGroup(state.rawStats, state.graphType);
      const retrievedStats = Service.getdailyGroupedStats(group);
      setState((ps) => ({ ...ps, graphData: retrievedStats }));
    }
  }

  useEffect(() => reloadStats(), []);

  useEffect(() => setGraphData(), [state.rawStats, state.graphType, state.dateType]);

  return (
    <BlockUi blocking={state.loading}>
      <CardSimple>
        <div className='d-flex justify-content-between'>
          <span className='h4 mr-2'>{`${addSpacesOnCaps(state.graphType)} - ${addSpacesOnCaps(state.dateType)}`}</span>
          <GraphTypeForm
            onRefresh={reloadStats}
            type={state.graphType}
            dateRange={state.dateType}
            onTypeChange={(v) => setState((ps => ({ ...ps, graphType: v })))}
            onDateRangeChange={(v) => setState((ps => ({ ...ps, dateType: v })))}
          />
        </div>
        {state.graphData && <Graph stats={state.graphData} dateType={state.dateType} />}
      </CardSimple>
    </BlockUi>
  );
}

export default DashboardPage(WithApiHelper(BankActivity));
