import React, { useState } from "react";
import { Navbar, Nav, NavItem, NavbarBrand } from "reactstrap";
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { useAppContext } from "../../app";
import { Button, ButtonHamburger } from "../../shared";
import axios from 'axios';
import getSettings from '../../../settings'
import Select from "react-select";

interface NavBarProps {
  brandText: string
  onToggleLeftSideBar: () => void
  onToggleRightSideBar: () => void
  navBarOpen: boolean
}

const settings = getSettings();

const Header: React.FC<NavBarProps> = ({ brandText, onToggleLeftSideBar, onToggleRightSideBar, navBarOpen }) => {

  const { user } = useAppContext();
  const getName = () => !user ? 'Guest' : user.displayName;

  function changeVersion(version: string) {
    axios.defaults.headers.common['api-version'] = version
  }

  const apiVersionOptions = [
    {value: '1.0', label: 'V1'},
    {value: '2.0', label: 'V2'},
    {value: '3.0', label: 'V3'}
  ]

  return (
    <Navbar color="light" light className="navbar shadow-sm p-3 mb-5 bg-white rounded" expand="md">
      <ButtonHamburger active={!navBarOpen} color='dark' className='mr-3' onClick={onToggleLeftSideBar} />
      <NavbarBrand tag={Link} to='/dashboard'>{brandText}</NavbarBrand>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Select placeholder={"Version"} options={apiVersionOptions} onChange={(e) => changeVersion(e!.value)}/>
          <span> {getName()}</span>
          <Button variant='transparent' iconVariant='primary' iconSize='lg' icon={faIdBadge} onClick={onToggleRightSideBar} />
        </NavItem>
      </Nav>
    </Navbar>
  )
}

export default Header;
