import React, { useEffect } from "react";
import useReactRouter from 'use-react-router';
import { CurrentUser } from "../../types/user-types";
import * as UserService from "../../services/user-service";
import { useAppContext } from "../app";
import { goToErrorPage } from "../error";

export interface withAdminUserProps {
  user: CurrentUser;
}

function WithAdminUser<P extends withAdminUserProps>(Component: React.ComponentType<P>) {
  return function WithUserComponent(props: Pick<P, Exclude<keyof P, keyof withAdminUserProps>>) {

    const { user } = useAppContext();
    const routeContext = useReactRouter();

    useEffect(() => {
      if (!UserService.isAdmin(user)) {
        goToErrorPage(routeContext.history, 'Oops', 'Unauthorised Access');
      }
    }, []);


    return <Component user={user!} {...props as P} />;
  }
}

export default WithAdminUser;