import React from 'react';
import { Alert, Button } from '../../../../shared';

interface LoginFormErrorProps {
  error?: string
  onReload: () => void
  onBack?: () => void
}

const LoginFormError: React.FC<LoginFormErrorProps> = ({ error, onReload, onBack }) => {
  return (
    <React.Fragment>
      <Alert variant='error' center message={error || 'An error occured.  Please try again'} />
      <Button bsVariant='primary' onClick={onReload}>Retry</Button>
      {onBack && <Button className='ml-2' bsVariant='secondary' onClick={onBack}>Back</Button>}
    </React.Fragment>
  );
}

export default LoginFormError;
